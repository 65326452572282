@font-face {
  font-family: "patriotics_demoregular";
  src: url("./data/patrioticsdemoregular-webfont.eot");
  src: url("./data/patrioticsdemoregular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./data/patrioticsdemoregular-webfont.woff2") format("woff2"),
    url("./data/patrioticsdemoregular-webfont.woff") format("woff"),
    url("./data/patrioticsdemoregular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ipaexgothicregular";
  src: url("./data/ipaexg-webfont.eot");
  src: url("./data/ipaexg-webfont.eot?#iefix") format("embedded-opentype"),
    url("./data/ipaexg-webfont.woff2") format("woff2"),
    url("./data/ipaexg-webfont.woff") format("woff"),
    url("./data/ipaexg-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.xyz20 {
  font-family: "patriotics_demoregular";
}
.xyz30 {
  font-family: "ipaexgothicregular";
}

@font-face {
  font-family: 'gemsbuck_01_blackblack';
  src: url('./data/gemsbuck01black-axn2g-webfont.eot');
  src: url('./data/gemsbuck01black-axn2g-webfont.eot?#iefix') format('embedded-opentype'),
       url('./data/gemsbuck01black-axn2g-webfont.woff2') format('woff2'),
       url('./data/gemsbuck01black-axn2g-webfont.woff') format('woff'),
       url('./data/gemsbuck01black-axn2g-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}
.xyz1s {
  font-family: "gemsbuck_01_blackblack";
}

@font-face {
  font-family: "wantonebold";
  src: url("./data/wantone-bold-webfont.eot");
  src: url("./data/wantone-bold-webfont.eot?#iefix") format("embedded-opentype"),
    url("./data/wantone-bold-webfont.woff2") format("woff2"),
    url("./data/wantone-bold-webfont.woff") format("woff"),
    url("./data/wantone-bold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
body {
  /* background-color: #333333; */
}

.wrapper {
  height: 100%;
}

.fixed-bg {
  height: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-top: 10%;
}

.scroll-bg {
  background-color: #0f0f0a;
}

/* first section */

.bg-1 {
  /* background-image: url(./imgs/intro-bg.jpg); */
  animation: visi 2.5s;
}
@keyframes visi {
  0% {
    background-image: none;
  }
  100% {
    background-image: none;
  }
}

*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@media screen and (max-width: 700px) {
  .tezt {
    height: 50%;
    width: 22%;
    /* padding-top: 3vh; */
    visibility: visible;
    animation: imgs 7s ease;
  }
  .card {
    position: relative;
    width: 100%;
    height: 100%;
    color: rgb(4, 0, 41);
    overflow: hidden;
    /* border-radius: 10px; */
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    /* padding: 1em; */
    padding-top: 24vh;
  }
  .cardfather {
    /* padding-top: 1%;
    margin-top: 1%;
    padding-bottom: 2%; */

    margin-top: -22vh;
    height: 100%;
    width: 100%;
    background-color: #321f63;
    visibility: visible;
    animation: example 2.8s;
  }
  .tezt2 {
    /* display: flex; */
    width: 2px;
    height: 100px;
  }
  .fiam {
    /* font-size: small; */
  }
  .fixed-bg {
    height: 100%;
    position: relative;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    /* padding-top: -10%; */
  }
  .bg-1 {
    /* background-image: url(./imgs/intro-bg.jpg); */
    height: 100%;
    /* position: absolute; */
  }
}
.flexer-word {
  font-size: 100%;
  animation: imz 1s infinite;
}
@keyframes imz {
  0% {
    font-size: 90%;
  }
  50% {
    font-size: 100%;
  }
  100% {
    font-size: 90%;
  }
}
.flexer-img-ig {
  animation: im2 1.5s ease-in-out infinite;
  padding-right: 7%;
  opacity: 0.8;
  width: 6vw;
  padding-left: 5%;
}
@keyframes im2 {
  0% {
    width: 10vw;
    border-radius: 0;
    /* padding-right: 5%;
    padding-left: 5%; */
  }
  50% {
    /* padding: 5%; */
    width: 10vw;
    opacity: 0.6;
    border-radius: 30%;
  }
  100% {
    width: 10vw;
    /* padding-left: 5%; */
    border-radius: 0;
  }
}
.flexer-img-ig2 {
  animation: im22 2s infinite;
  width: 6vh;
  opacity: 0.8;
  border-radius: 2%;
  margin-right: auto;
  margin-left: auto;
}
@keyframes im22 {
  50% {
    border-radius: 25%;
  }
}
.tezt2 {
  width: 100px;
  height: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.transp {
  opacity: 0;
}
.tezt {
  /* height: 50%;
  width: 10%; */
  width: 10vw;
  height: 43vh;
  /* padding-bottom: 4%; */
  /* padding-top: 3vh; */
  visibility: visible;
  animation: imgs 7s;
}
@keyframes imgs {
  0% {
    /* background-color: #f7cbf8; */
    visibility: visible;
    opacity: 0;
  }
  25% {
    visibility: visible;
    opacity: 0.01;
  }
  50% {
    opacity: 1;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
.fiam {
  visibility: visible;
  animation: fia 4.3s linear;
}
@keyframes fia {
  0% {
    visibility: hidden;
  }
  25% {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
    opacity: 0.1;
  }
  /* 60% {
    visibility: visible;
    opacity: 1;
  }
  75% {
    visibility: visible;
    opacity: 1;
  } */
  100% {
    visibility: visible;
    opacity: 1;
  }
}
.card {
  position: relative;
  /* width: 100vh; */
  height: 100vh;
  color: rgb(4, 0, 41);
  overflow: hidden;
  /* border-radius: 10px; */
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  /* padding: 1em; */
  padding-top: 25vh;
}
.cardfather {
  /* padding-top: 1%;
  margin-top: 1%;
  padding-bottom: 2%; */
  background-color: #321f63;
  margin-top: -25vh;
  height: 110vh;
  /* background: transparent; */
  visibility: visible;
  animation: example 4.8s linear;
}
@keyframes example {
  0% {
    /* background: rgba(231, 147, 252, 0.164); */
    background-color: #321f63;
    visibility: visible;
  }
  15% {
    visibility: visible;
  }
  26% {
    background: #220e53;
    visibility: visible;
  }
  50% {
    background: #220e53;
    visibility: visible;
  }
  60% {
    background: #220e53;
    opacity: 1;
    visibility: visible;
  }
}
span {
  position: absolute;
  margin-top: 10vh;
  /* border-radius: 100vmax; */
}

.top {
  top: 0;
  left: 0;
  width: 0;
  height: 7px;
  background: linear-gradient(
    90deg,
    transparent 50%,
    rgba(255, 132, 17, 0.5),
    rgb(255, 244, 85)
  );
}

.bottom {
  right: 0;
  bottom: 0;
  height: 7px;
  background: linear-gradient(
    90deg,
    rgb(255, 163, 88),
    rgba(175, 120, 0, 0.5),
    transparent 50%
  );
}

.right {
  top: 0;
  right: 0;
  width: 7px;
  height: 0;
  background: linear-gradient(
    180deg,
    transparent 30%,
    rgba(116, 43, 1, 0.5),
    rgb(122, 69, 0)
  );
}

.left {
  left: 0;
  bottom: 0;
  width: 7px;
  height: 0;
  background: linear-gradient(
    180deg,
    rgb(255, 255, 113),
    rgba(255, 255, 113, 0.5),
    transparent 70%
  );
}

.top {
  animation: animateTop 1.5s ease-in-out reverse;
}

.bottom {
  animation: animateBottom 1.5s ease-in-out;
}

.right {
  animation: animateRight 1s linear;
}

.left {
  animation: animateLeft 1s ease-in-out reverse;
}

@keyframes animateTop {
  25% {
    width: 100%;
    opacity: 1;
  }

  30%,
  100% {
    opacity: 1;
  }
}

@keyframes animateBottom {
  0% {
    opacity: 1;
    width: 0;
  }

  65% {
    opacity: 1;
    width: 100%;
  }

  /* 26%, */
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    width: 0;
  }
}

/* @keyframes animateBottom {
  0%,
  50% {
    opacity: 1;
    width: 0;
  }

  75% {
    opacity: 1;
    width: 100%;
  }

  76%,
  100% {
    opacity: 1;
  }
} */

@keyframes animateRight {
  0%,
  25% {
    opacity: 0;
    height: 0;
  }

  50% {
    opacity: 1;
    height: 100%;
  }

  55%,
  100% {
    height: 100%;
    opacity: 0;
  }
}

@keyframes animateLeft {
  0%,
  75% {
    opacity: 0;
    bottom: 0;
    height: 0;
  }

  100% {
    opacity: 1;
    height: 100%;
  }
}

.anim {
  animation: asnim 6s linear;
  visibility: visible;
}

@keyframes asnim {
  0% {
    visibility: hidden;
  }
  25% {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
    transform: translateY(100%);
  }
  75% {
    visibility: visible;
    transform: translateY(0%);
  }
  80% {
    transform: translateY(100%);
    /* transition: 0.1s; */
    visibility: visible;
  }
  82% {
    transform: translateY(0%);
    /* transition: 0.1s; */
    visibility: visible;
  }
  84% {
    transform: translateY(100%);
    /* transition: 0.1s; */
    visibility: visible;
  }
  87% {
    transform: translateY(0%);
    /* transition: 0.1s; */
    visibility: visible;
  }
  /* 80%, */
  90% {
    transform: translateY(0%);
    /* transition: 0.5s; */
    visibility: visible;
  }
}
th,
td {
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  font-size: large;
  width: 2%;
}

.font-link {
  font-family: "Sofia", cursive;
}

@media screen and (max-width: 700px) {
  td {
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    /* padding-left: 6%; */
    font-size: small;
  }
}
.disapp {
  animation: dissi 3.39s;
  opacity: 1;
}
@keyframes dissi {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    visibility: hidden;
    opacity: 1;
  }
}
.disapp-img {
  animation: dissiimg 3.4s;
  opacity: 1;
}
@keyframes dissiimg {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    visibility: hidden;
    opacity: 1;
  }
}
.nnah {
  animation: boyx 4s;
  visibility: hidden;
}
@keyframes boyx {
  0% {
    visibility: hidden;
    transform: translateX(200%);
  }
  100% {
    visibility: visible;
    transform: translateX(-100%);
  }
}
.call-me {
  animation: caller 3s infinite;
  /* transform: translateY(50%); */
}
@keyframes caller {
  50% {
    transform: rotate(-70deg);
  }
  52% {
    transform: translateY(-80%) rotate(1deg);
  }
  54% {
    transform: translateY(-65%) rotate(-70deg);
  }
  56% {
    transform: translateY(-50%) rotate(-70deg);
  }
  58% {
    transform: translateY(-50%) rotate(1deg);
  }
  60% {
    transform: translateY(-50%) rotate(-70deg);
  }
  62% {
    transform: translateY(-50%) rotate(-70deg);
  }
  64% {
    transform: translateY(-50%) rotate(1deg);
  }
  66% {
    transform: translateY(-50%) rotate(-70deg);
  }
  68% {
    transform: translateY(-50%) rotate(-70deg);
  }
  70% {
    transform: translateY(-50%) rotate(1deg);
  }
  72% {
    transform: translateY(-50%) rotate(-70deg);
  }
  74% {
    transform: translateY(-50%) rotate(-70deg);
  }
  76% {
    transform: translateY(-50%) rotate(1deg);
  }
  78% {
    transform: translateY(-50%) rotate(-70deg);
  }
}
.vibr {
  animation: vibra 2s;
  /* transform: translateY(50%); */
  transform: translateX(-3%);
}
@keyframes vibra {
  2% {
    transform: translateX(3%);
  }
  4% {
    transform: translateX(-3%);
  }
  6% {
    transform: translateX(3%);
  }
  8% {
    transform: translateX(-3%);
  }
  10% {
    transform: translateX(3%);
  }
  12% {
    transform: translateX(-3%);
  }
  14% {
    transform: translateX(3%);
  }
  16% {
    transform: translateX(-3%);
  }
  18% {
    transform: translateX(3%);
  }
  20% {
    transform: translateX(-3%);
  }
  22% {
    transform: translateX(3%);
  }
  24% {
    transform: translateX(-3%);
  }
  26% {
    transform: translateX(3%);
  }
  28% {
    transform: translateX(-3%);
  }
  30% {
    transform: translateX(3%);
  }
  32% {
    transform: translateX(-3%);
  }
}
